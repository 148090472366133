<template>
  <div>
    <el-form :model="form"
             label-width="80px"
             ref="formRef">
      <!-- <el-row :gutter="10">
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6"> -->
      <!-- <el-form-item label="年级">
            <el-select v-model="form.grade_id"
                       style="width:100%"
                       clearable
                       @change="gradeChange"
                       placeholder="年级">
              <el-option v-for="item in gradeList"
                         :key="item.grade_id"
                         :label="item.grade_name"
                         :value="item.grade_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :lg="6"
                :md="12"
                :sm="12"
                :xl="6">
          <el-form-item label="班级">
            <el-select v-model="form.class_id"
                       clearable
                       filterable
                       style="width:100%"
                       @change="classChange"
                       placeholder="班级">
              <el-option v-for="item in classList"
                         :key="item.class_id"
                         :label="item.class_name + '-' + item.year_desc"
                         :value="item.class_id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->

      <el-col :lg="6"
              :md="12"
              :sm="12"
              :xl="6">
        <el-form-item label="开始时间">
          <el-date-picker v-model="form.timeBegin"
                          style="width:100%"
                          @focus="$forbid"
                          @change="search"
                          type="datetime"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="开始时间" />
        </el-form-item>
      </el-col>
      <el-col :lg="6"
              :md="12"
              :sm="12"
              :xl="6">
        <el-form-item label="结束时间">
          <el-date-picker v-model="form.timeEnd"
                          @change="search"
                          style="width:100%"
                          @focus="$forbid"
                          type="datetime"
                          default-time="23:59:59"
                          value-format="yyyy-MM-dd HH:mm:ss"
                          placeholder="结束时间" />
        </el-form-item>
      </el-col>
      <el-col :lg="9"
              :md="24"
              :sm="24 "
              style="margin-bottom:10px"
              :xl="9">
        <el-button type="primary"
                   @click="search">提交</el-button>
      </el-col>
      </el-row>
    </el-form>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { nowDateTime, frontDay } from '@/utils/date.js'

export default {
  data () {
    return {
      form: {
        // grade_id: '',
        // class_id: '',
        timeBegin: frontDay(7),
        timeEnd: nowDateTime(),
      },
      gradeList: [],
      classList: [],
      schoolList: [],
      page: 1,
      size: 10,
      totalElements: 0,
      tableLoading: false,
      tableData: [],
      pageName: 'cz_edu_statistics_admin',
      tableColumns: [
        { prop: 'edu_school_name', align: 'center', label: '学校名', width: '', showToopic: false },
        { prop: 'edu_user_id', align: 'center', label: '教师ID', width: '', showToopic: true },
        { prop: 'edu_user_name', align: 'center', label: '教师姓名', width: '', showToopic: false },
        { prop: 'subject_name', align: 'center', label: '教师科目', width: '', showToopic: false },
        { prop: 'paper_count', align: 'center', label: '提交教师试卷数', width: '', showToopic: false },
        { prop: 'user_paper_count', align: 'center', label: '学生总做卷次数', width: '', showToopic: false },
        { prop: 'user_score_rate', align: 'center', label: '学生得分率', width: '', showToopic: false },
        {
          prop: 'd', align: 'center', label: '操作', width: '', type: 'img', btns: [
            { content: '查看', type: 'look', event: 'toDetails' },
          ]
        },
      ]
    }
  },
  created () {
    // this.getSchoolList()
  },
  mounted () {
    this.setTablesColums()
    this.getGradeList()
    this.getClassList()
  },
  methods: {
    gradeChange () {
      this.form.class_id = ''
    },
    classChange () {
      this.form.grade_id = ''
    },
    getGradeList () {
      this.$http({
        url: '/api/v1/school/select_grade',
        method: 'get'
      }).then(res => {
        this.gradeList = res.data.list
      })
    },
    getClassList () {
      this.$http({
        url: '/api/v1/special/tc_lead_class',
        method: 'get',
      }).then(res => {
        this.classList = Object.values(res.data)
      })
    },
    
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    initData (page, limit) {
      let form = JSON.parse(JSON.stringify(this.form))
      form.page = page
      form.limit = limit
      this.$http({
        url: '/api/v1/combination/stats',
        method: 'get',
        params: form
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    getSchoolList () {
      this.$http({
        url: '/api/v1/school/get_school',
        method: 'get'
      }).then(res => {
        this.schoolList = res.data.list
      })
    },
    toDetails (row) {
      let form = {
        edu_user_id: row.edu_user_id,
        subject_id: row.subject_id,
        timeBegin: this.form.timeBegin,
        timeEnd: this.form.timeEnd,
      }
      this.$router.push('/testPapercomposition/teacherDetail?form=' + JSON.stringify(form))
    },
    // 刷新
    refresh () {
      this.$refs.pagination.shuaxin()
    },
    // 查询
    search () {
      this.$refs.pagination.toFirstPage()
    },
    setList (list) {
      this.showList = list
    },
    exportExcel () {
      let form = {
        timeBegin: this.form.timeBegin,
        timeEnd: this.form.timeEnd
      }
      this.$http({
        url: '/api/v1/combination/stats_export',
        method: 'get',
        responseType: "blob",
        params: form
      }).then(res => {
        const a = document.createElement("a")
        a.href = window.URL.createObjectURL(res.data)
        a.download = `${this.form.timeBegin}-${this.form.timeEnd}教师组卷统计.xlsx`
        a.click()
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>